import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Event from '../components/Event'
import Hero from '../components/Hero'

const PodcastPage = ({ data }) => {
  const pageTitle = 'LAURIE ON TECH Podcasts'

  return (
    <Layout>
      <Helmet title={pageTitle} />
      <Hero
        image={data.banner.childImageSharp.gatsbyImageData}
        title="PODCAST APPEARANCES"
      />
      <section id="speaking" className="wrapper style4 container">
        <div className="container">
          <h2 className="hs h2">Podcast Episodes</h2>
          <p>
            Note that this may not be the most up-to-date. Check out my{' '}
            <a href="https://timeline.laurieontech.com/">timeline</a>!
          </p>
          <div className="wrapper" id="speakwrap">
            {data.allPodcastsYaml.edges.map(({ node }) => (
              <Event event={node} />
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allPodcastsYaml(sort: { fields: [index], order: DESC }) {
      edges {
        node {
          conference
          year
          url
          date
          image {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
          talks {
            title
            video
            type
          }
        }
      }
    }
    banner: file(relativePath: { eq: "speaking/colorful.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          aspectRatio: 2.33
          width: 700
          transformOptions: { cropFocus: CENTER }
          webpOptions: { quality: 100 }
          jpgOptions: { quality: 100 }
        )
      }
    }
  }
`

export default PodcastPage
